.group-playbook-wrapper {
  padding: 0 24px;
}

.group-playbook-wrapper svg {
  color: var(--color-text-primary);
}

.bubble {
  position: relative;
  padding: 12px 20px;
  border-radius: 16px;
  background-color: var(--color-quaternary);
  font-size: 16px;
  line-height: 22px;
  animation-fill-mode: forwards;
  font-style: italic;
  margin-bottom: 4px;
}

.bubble::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 28px;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-top-color: var(--color-quaternary);
  border-bottom: 0;
  border-left: 0;
  margin-left: 6px;
  margin-bottom: -12px;
}
