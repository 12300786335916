.meetingDrawer {
  box-shadow:
    5px 5px 10px rgba(0, 0, 0, 0.1),
    -5px -5px 10px rgba(0, 0, 0, 0.1),
    5px -5px 10px rgba(0, 0, 0, 0.1);
  margin-left: var(--side-nav-width);
  min-width: calc(95% - var(--side-nav-width));
}

@media (max-width: 1080px) {
  .meetingDrawer {
    margin-left: 0;
    min-width: 95%;
  }
}
