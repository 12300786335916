.userInfo input,
.userInfo select {
  width: 100%;
  box-sizing: border-box;
}

.basicInfo {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-template-rows: 75px 75px;
  row-gap: 8px;
  column-gap: 32px;
}

.workInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 8px;
  column-gap: 32px;
  margin-top: 8px;
}

.email {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sharingPersonalizationSettings {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
}

.options {
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin: 10px 0;
}

.options label {
  display: flex;
  align-items: center;
  column-gap: 4px;
  flex-wrap: wrap;
}

.shareUrlPreview {
  width: fit-content;
  background-color: #f5f5f5;
  border: 1px solid #dedede;
  border-radius: 8px;
}

form.addEmail button {
  display: inline-block;
}

.settingName {
  display: flex;
  align-items: center;
  column-gap: 0.2em;
  clear: both;
  margin-bottom: 16px;
}

.multiFactorAuth {
  display: grid;
  grid-template-columns: 1fr;
}

.twoFactorButton {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: fit-content;
}

.notifications label {
  display: flex;
  align-items: center;
  column-gap: 4px;
  flex-wrap: wrap;
}

.passwordFields {
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
  margin: 20px 0;
}

.currentPassword {
  grid-column: 1 / span 2;
}

@media (max-width: var(--main-content-width)) {
  form.addEmail {
    flex-direction: column;
    align-items: center;
  }

  form.addEmail button {
    margin-top: 20px;
  }

  .deleteAccount {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .passwordFields {
    grid-template-columns: 1fr;
  }

  .currentPassword {
    grid-column: 1;
  }

  .notifications label {
    font-size: 0.9rem;
  }

  .workInfo {
    grid-template-columns: 1fr;
  }

  .basicInfo {
    grid-template-columns: 1fr;
  }

  .options {
    flex-direction: column;
    row-gap: 10px;
  }

  .shareUrlPreview {
    flex-direction: column;
    padding: 8px 8px;
    width: 100%;
    box-sizing: border-box;
  }

  .shareUrlPreview input {
    width: 100%;
  }
}
.genderDropdown {
  width: calc(50% - 16px);
  margin-top: 8px;
  margin-bottom: 8px;
}
