@keyframes placeholder-shimmer {
  from {
    background-position: -468px 0;
  }
  to {
    background-position: 468px 0;
  }
}

.loadingButton {
  animation: placeholder-shimmer 1.8s linear infinite;
  animation-fill-mode: forwards;
  background: var(--color-loading-bar);
  background: linear-gradient(
    to right,
    var(--color-loading-bar) 8%,
    var(--color-loading-bar-shimmer) 38%,
    var(--color-loading-bar) 54%
  );
  background-size: 1000px 640px;
  color: transparent;
}
