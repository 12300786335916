.quickMeetingTips {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.suggestion {
  border-left-width: 2px;
  border-left-style: solid;
  padding: 2px 0.5em;
  margin: 0.5em 0;
  font-size: 0.875rem;
}

.dos .suggestion {
  border-color: var(--color-accent-action-medium);
}

.donts .suggestion {
  border-color: var(--color-accent-destructive-light);
}

.paywalled {
  filter: blur(8px);
  pointer-events: none;
}

@media (max-width: min(100vw, 1024px)) {
  .quickMeetingTips {
    padding: 0 20px;
  }

  .suggestions {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}
