.welcome-container {
  padding: 40px;
}

.greeting-text {
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
