.body {
  font-family: var(--font-lato);
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

.body input,
.body select {
  font-size: 0.875rem;
  line-height: 1.5rem;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
}

.body button {
  font-family: var(--font-lato);
}

.body input {
  font-family: var(--font-lato);
  font-size: 0.875rem;
}

.body input::placeholder {
  font-family: var(--font-lato);
}

.body label {
  font-family: var(--font-lato);
  font-size: 1rem;
  line-height: 1.4rem;
}
