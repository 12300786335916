.identityCircle {
  display: inline;
  position: relative;
}

.identityRect {
  display: inline;
  position: relative;
  border-radius: 4px;
}

.imageCircle {
  clip-path: inset(0 0 0 0 round 50%);
}

.defaultImg {
  background: var(--color-avatar-bg);
  border-radius: 4px;
}
