.layout {
  display: flex;
  width: 1176px;
  height: 179px;
  padding: 24px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.paymentInfo {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  gap: 16px;
  padding-bottom: 24px;
  display: flex;
}

.subText {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 26px;
}

.actionButtonLayer {
  display: flex;
  height: 48px;
  padding: 8px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.25);
}

.buttonGroup {
  display: flex;
  gap: 16px;
}

.errorMarkContainer {
  margin-top: 2px;
}

.errorMarkContainer svg {
  height: 27px;
  width: 27px;
}

@media (max-width: 568px) {
  .paymentInfo {
    font-size: 16px;
  }

  .subText {
    width: 260px;
  }
}

@media (max-width: 1268px) {
  .layout {
    align-items: center;
  }
}
