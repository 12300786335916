.center {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.title,
.subtitle {
  margin-right: 0;
}

@media (max-width: 600px) {
  .top {
    flex-direction: column;
  }
}
