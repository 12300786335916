.discBarChart {
  width: 100%;
  height: 100%;
}

.double {
  width: 100%;
  height: 100%;
}

.double h4 {
  font-size: 1.2rem;
  margin-bottom: 0;
}

.bar-chart {
  border: 1px solid black;
}

@media (max-width: var(--main-content-width)) {
  .discBarChart {
    width: 60%;
  }
}
