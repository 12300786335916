.displayArea .with-click-handler polygon {
  cursor: pointer;
}

.polygon-fill polygon {
  transition: all 0.2s;
}

.stroke-gray {
  stroke: var(--color-disc-line);
  stroke-miterlimit: 10;
}
