.heroSection {
  background-color: var(--color-bg-panel);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 29px;
  box-sizing: border-box;
}

.pill {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 8px 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
}

.bio {
  width: var(--main-content-width);
  column-gap: 48px;
  grid-template-columns: auto 1fr;
}

.short-overview {
  margin: 12px 0;
  font-size: 1.5rem;
  line-height: 1.6em;
  font-weight: 400;
}

.subtitle {
  color: var(--color-text-secondary);
  font-size: 1.125rem;
}

.overview {
  display: grid;
  padding: 5px 0;
}

.overview h1 {
  margin-top: 0;
  margin-bottom: 4px;
  line-height: 1.2em;
}

.discBlurbSection {
  margin: 12px 0;
  font-size: 1.5rem;
  line-height: 1.6em;
  font-weight: 400;
  display: flex;
  align-items: center;
}

@media (max-width: min(100vw, 1040px)) {
  .bio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .overview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .overview h1 {
    text-align: center;
  }

  .overview p {
    font-size: 1rem;
    text-align: center;
  }

  .pills {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
  }
}

@media (max-width: 1040px) {
  .heroSection {
    padding: 29px 0;
  }

  .subtitle {
    display: block;
    text-align: center;
  }

  .short-overview {
    display: inline;
    text-align: center;
    padding: 0 20px;
  }
}
