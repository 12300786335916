.container {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 0 0 10px;
  position: relative;
}

.flexFill {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.uncontained {
  max-width: inherit;
}

@media (max-width: 1072px) {
  .container {
    padding: 0 10px;
  }
  .container.uncontainedSmall {
    padding: 0;
  }
}
