.select {
  border-radius: 3px;
  border: 1px solid var(--color-text-input-border-secondary);
  background: var(--color-text-input-bg-secondary);
  padding: 9px 12px;
  color: var(--color-text-primary);
  width: 100%;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5' fill='none'%3E%3Cpath d='M1.05469 0.600586L4.05469 3.60059L7.05469 0.600586' stroke='%236c6c6c' stroke-opacity='0.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
  margin: 4px 0;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.caret {
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  color: var(--color-text-secondary);
}

.select::placeholder {
  color: var(--color-text-primary);
}

.select.disabled {
  background-color: var(--color-text-input-bg-disabled);
}
