.profilePage {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  min-height: 100vh;
}

.profilePage h1,
.profilePage h2 {
  scroll-margin-top: 50px;
}

@media (max-width: min(100vw, 1040px)) {
  .profilePage > .profileMain {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .profilePage > .profileMain article {
    width: 100vw;
    box-sizing: border-box;
  }
}
