.caret path {
  stroke: var(--color-text-primary);
}

.contentIcon {
  width: 150px;
  height: 150px;
}

.contentIcon svg {
  width: 150px;
  stroke: var(--color-text-primary);
}
