.container {
  width: 120px;
  height: 1px;
  background-color: blue;
  position: relative;
}

.container::before,
.container::after {
  content: '';
  position: absolute;
  top: -1px;
  background-color: blue;
  height: 3px;
  width: 3px;
  border-radius: 50%;
}

.container::before {
  left: -2px;
}

.container::after {
  right: -2px;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.marginVertical {
  margin-top: 16px;
  margin-bottom: 16px;
}
