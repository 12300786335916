.label {
  color: magenta;
  font-weight: bold;
}

.bottomError {
  color: magenta;
  font-size: 14px;
  font-style: italic;
  font-family: var(--font-lato);
  font-weight: 300;
}
