.sideNavBar {
  width: 75px;
  max-width: var(--side-nav-width);
  height: 100vh;
  background: var(--color-bg-primary);
  position: sticky;
  top: 0;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.1s ease;
}

.miniView {
  position: fixed;
}

.mobileHamburger {
  display: none;
  position: absolute;
  cursor: pointer;
  padding: 8px;
  color: var(--color-text-primary);
  font-size: 32px;
}

.expanded {
  width: var(--side-nav-width);
}

.expanded .navigationContainer {
  width: calc(300px - 18px);
}

@media (max-width: min(100vw, 1040px)) {
  .mobileHamburger {
    display: block;
  }

  .mobileOpen {
    display: flex;
  }

  .navigationContainer {
    display: none;
  }

  .navigationContainer.mobileOpen {
    display: flex;
  }

  .sideNavBar {
    width: 50px;
    position: absolute;
    background: transparent;
    overflow-y: unset;
  }

  .sideNavBar.mobileOpen {
    width: 300px;
    height: 100vh;
    background: var(--color-bg-primary);
  }

  .mobileOpen .mobileHamburger {
    right: 8px;
  }
}
