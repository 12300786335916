.register-form {
  display: flex; /* Enable Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 80vh; /* Optional: Set a height for vertical centering */
  color: var(--main-text, rgba(255, 255, 255, 0.75));
}

.register-form a {
  text-decoration: none;
  color: white;
}

.register {
  display: flex;
  width: 780px;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--color-check);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
}

.register h2 {
}

.form-container {
  display: flex;
  width: 493.5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.loginArea {
  width: 100%;
  padding: 40px 30px;
  box-sizing: border-box;
}

.fullRow {
  display: block;
  width: 100%;
  padding-bottom: 8px;
  color: var(--color-tooltip-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140.625%;
}

.privacy-input {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 0 40px 0;
}

.button-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 28px;
  gap: 16px;
}

.button-group .sso {
  padding-bottom: 16px;
}

.button-group a {
  color: var(--color-link);
}
