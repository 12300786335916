.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 32px;
  margin-top: 3rem;
  width: 100%;
  max-width: 1200px;
  margin: 40px 40px;
  justify-self: center;
  align-self: center;
}

.intro {
  display: grid;
  grid-template-columns: auto auto;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  column-gap: 5rem;
}

@media only screen and (max-width: 1170px) {
  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    margin-bottom: 2rem;
  }
}

.installInstructions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 2.3em;
}

.installInstructions h1 {
  color: black;
  text-align: center;
}

.howItWorks {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px;
  padding-bottom: 80px;
  line-height: 2em;
  align-self: center;
  justify-self: center;
  font-size: 1.2rem;
}

.howItWorks h2 {
  margin-left: 1rem;
  font-size: 3rem;
  font-weight: 700;
}

.howItWorks hr {
  background-color: gray;
  height: 3px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0;
}

.howItWorks > ol {
  max-width: 60rem;
  margin-left: 2rem;
  padding-inline-start: 0;
}

.howItWorks > ol ol {
  padding-inline-start: 1rem;
  list-style-type: lower-latin;
}

.howItWorks > ol p {
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
}

.howItWorks > ol > li {
  font-weight: bold;
  margin-top: 1.5em;
}

.howItWorks > ol > li p,
.howItWorks > ol > li li {
  font-weight: normal;
}
