.checkbox {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  margin-right: 0.5em;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.checkbox .checkmark {
  background-color: var(--color-checkbox-unchecked);
  border: 1px solid var(--color-checkbox-border);
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25%;
  pointer-events: none;
}

.checkbox .checkmark::after {
  content: '';
  position: absolute;
  display: none;
  width: 4px;
  height: 9px;
  border: solid var(--color-check);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox input:checked ~ .checkmark {
  background-color: var(--color-checkbox-checked);
  border: 0;
}

.checkbox input:checked ~ .checkmark::after {
  display: block;
}

.checkbox:hover .checkmark {
  background-color: var(--color-checkbox-hovered);
  border: 1px solid var(--color-checkbox-hovered-border);
}

.checkbox input:not(:checked):hover ~ .checkmark::after {
  border-color: var(--color-check-hovered);
  display: block;
}
