.title {
  font-size: 32px;
  font-family: var(--font-lato);
  font-weight: 400;
  margin: 16px 0 8px;
  line-height: 36px;
  color: $material-87;
  @media (min-width: 600px) {
    margin-right: 150px;
    margin: 0 0 8px;
  }
}

.time {
  padding-left: 12px;
  font-size: 65%;
  color: #777777;
  font-family: var(--font-lato);
  font-weight: 400;
}

@media (min-width: 600px) {
  .title {
    margin-right: 150px;
    margin: 0 0 8px;
  }
}
