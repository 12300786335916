.chart-area {
  min-width: 264px;
}

.toggles {
  display: flex;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  column-gap: 24px;
  max-width: 13em;
  font-size: 0.9rem;
}

.toggles > div {
  color: var(--color-text-primary);
  border: 1px solid var(--color-button-sustainable-border);
  background: var(--color-button-sustainable-bg);
  padding: 4px 24px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}

.toggles > div:hover {
  background-color: var(--color-button-sustainable-bg-hover);
}

.toggles > div.selected {
  background-color: var(--color-button-sustainable-bg-active);
  border: 1px solid var(--color-button-sustainable-border-active);
}
