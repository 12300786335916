.container {
  display: flex;
  flex-direction: column;
}

.container>.input {
  border-radius: 3px;
  border: 1px solid var(--color-text-input-border);
  background: var(--color-text-input-bg);
  padding: 8px 12px;
  margin: 4px 0;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--color-text-primary);
}

.container>.input.secondary {
  outline: none;
  border: 1px solid var(--color-text-input-border-secondary);
  background: var(--color-text-input-bg-secondary);
}

.container>.input.noMargin {
  margin: 0;
}

.container>.input::placeholder {
  color: var(--color-text-secondary);
}

.container>.label {
  color: var(--color-text-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.5px;
}

.container>.helperText {
  color: var(--color-text-primary);
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 20px;
}

.container.disabled>.input {
  background-color: var(--color-text-input-bg-disabled);
}

.container.error>.input {
  border-color: var(--color-text-input-border-error);
}

.container.error>.label {
  color: var(--color-text-input-border-error);
}

.container.error>.helperText {
  color: var(--color-text-input-border-error);
}
