.layout {
  display: flex;
  width: 1176px;
  height: 189px;
  padding: 46px 12px 12px 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.paymentInfo {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
}

.icon svg {
  height: 27px;
  width: 27px;
}

.actionButtonLayer {
  display: flex;
  height: 48px;
  padding: 8px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.25);
}

@media (max-width: 568px) {
  .paymentInfo {
    font-size: 24px;
  }
}
