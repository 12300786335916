.password-container {
  padding: 40px;
}

.password-text {
  padding-bottom: 28px;
}

.password-text h2 {
  padding-bottom: 32px;
}

.error {
  color: #ff0000;
  padding-bottom: 16px;
}
