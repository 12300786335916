.form input {
  width: 100%;
  height: 3.14em;
  box-sizing: border-box;
  padding: 11px 8px;
  margin-top: 8px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}

.registerCta {
  text-align: center;
  color: #6c6c6c;
}

.buttonContainer {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.oktaButton {
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid black;
  padding: 8px 16px;
  text-transform: uppercase;
  width: fit-content;
  font-weight: 700;
  cursor: pointer;
}

.oktaButton:disabled {
  background-color: #e3e3e3;
  cursor: not-allowed;
}

.oktaButton:hover {
  border: 1px solid black;
  color: black;
  background-color: #cecece;
}
